@font-face {
  font-family: "Lembra";
  src: url('./Font/lembra-regular-webfont.woff'),
    url('./Font/lembra-regular-webfont.woff2');
  font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./Font/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Font/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Font/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Font/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Font/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Font/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

:root {
  --fonts: 'Open Sans', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #141414;
  font-family: var(--fonts);
  color: white;
  font-size: 18px;
  font-weight: 300;
}

/* open-sans-regular - latin */

button {
  font-family: var(--fonts);
}

p {
  font-size: 18px;
  line-height: 150%;
}

::-webkit-scrollbar {
  background-color: #222;
}

::-webkit-scrollbar-thumb {
  background-color: #21ce5f;
}

.mainDiv {
  margin-left: auto;
  margin-right: auto;
}

button {
  background-color: #21ce5f;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  transition: 0.5s;
}

button:hover {
  color: #21ce5f;
  background-color: #2b2679;
}

.header {
  background-color: #141414;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 100px;
  position: fixed;
  padding-top: 1%;
  padding-bottom: 1%;
  z-index: 10;
}

.blogHeaderText {
  margin: 15px;
  cursor: pointer;
}

.headerImage {
  cursor: pointer;
  max-width: 50%;
}

.blogHeaderText:hover {
  color: #21ce5f;
  text-decoration: underline;
}

.headerHelpButton {
  padding: 15px 15px;
  font-weight: 700;
  font-size: 18px;
  color: black;
}

.headerSide {
  align-self: center;
  justify-content: space-evenly;
}

.firstContent {
  width: 100%;
  height: 1000px;
  background-image: url('./Media/BackgroundFirstContent.webp');
  background-attachment: fixed;
  background-position: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstContentDiv {
  margin: 0 17.3% 0 17.3%;
}

p {
  font-size: 21px;
  line-height: 150%;
}

.wrapper {
  font-size: 71px;
}

#helpButtonContent {
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-weight: bold;
}

h1, h2, h3 {
  color: #21ce5f;
}

.landingContent {
  margin: 0 17.3% 0 17.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landingContent h2 {
  margin-top: 2%;
  font-size: 43px;
  line-height: 150%;
  text-align: center;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.kaizenMethod {
  max-width: 507px;
  margin-top: 1%;
  margin-bottom: 2%;
}

.servicesDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #222;
  border-radius: 20px;
  box-shadow: 0 0 30px -2px rgb(0 0 0 / 11%);
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 3%;
}

.clientsDiv {
  margin-top: 2%;
  text-align: center;
}

.clientsImgDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.clientsImgDiv img{
  max-width: 20%;
  max-height: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

#fametro {
  max-height: 125px;
  margin-top: 6%;
}

.teamDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.teamSubDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.teamPerson {
  cursor: pointer;
  text-align: center;
  height: 480px;
  width: 395px;
  padding-left: 5%;
  padding-right: 5%;
}

.teamPerson h3{
  font-weight: 700;
}

.teamPerson h4{
  font-style: italic;
}

.teamPerson img {
  max-height: 176px;
  max-height: 176px;
  border-radius: 100%;
}

.contactDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.contactInfo {
  width: 50%;
  padding-right: 15%;
}

.contactForm {
  width: 50%;
}

.contactInfo h2 {
  color: white;
  text-align: start;
}

.socialAndCertificationsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.socialAndCertificationsDiv p {
  margin-top: 0;
  margin-bottom: 0;
}

#kaizenGreentext {
  color: #21ce5f;
}

.socialDiv {
  width: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.socialDiv img {
  cursor: pointer;
}

.certificationsDiv {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 115px;
  margin-bottom: 5%;
}

#xabradi {
  max-width: 211px;
  cursor: pointer;
}

#carbonext {
  max-width: 100px;
  cursor: pointer;
}

#adventures {
  max-width: 200px;
  margin-left: 30px;
  height: auto;
  cursor: pointer;
}

.propositionStartDiv {
  margin-top: 75px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.propositionKaizenLogo {
  width: 400px;
}

.adventuresDiv {
  margin-top: 300px;
  display: flex;
  flex-direction: row;
}

.webdesignPrinciple {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.webdesignPrincipleText {
  margin-right: 50px;
  font-size: 65px;
  padding: 15px;
  background-color: #2e2a83;
}

.webdesignPrincipleText h3 {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 65px;
}

.webdesignPrinciples {
  margin-top: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webdesignPrinciples h1 {
  font-size: 71px;
}

.propositionDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webdesignProject {
  margin-top: 150px;
  margin-bottom: 150px;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.webdesignProject img {
  margin-top: 50px;
  max-width: 75%;
}

.webdesignProject h3 {
  margin-bottom: 75px;
  font-weight: 650;
  font-size: 45px;
}

.proposition {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propositionHeaders {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propositionProjectDetails {
  margin-top: 50px;
  width: 80%;
}

.propositionEndingDiv {
  /* background-image: url('./Media/Proposition/obrigado.gif');
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat; */
  background-position: 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 400px;
  padding-bottom: 400px;
}

.webdesignProjectsDiv h1 {
  font-size: 71px;
}

.webdesignProjectsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allPropositionsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createPropositionBtn {
  margin-top: 100px;
  font-size: 24px;
  padding: 1% 2.5%  1% 2.5%;
}

.registerPropositionBtn {
  padding: 0.5% 1.5%  0.5% 1.5%;
}

.newPropositionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 2.5% 10% 2.5% 10%;
}

.propositionCard {
  padding: 20px 70px 20px 70px;
  margin: 25px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222;
}

.propositionCard button {
  padding: 2.5% 5%  2.5% 5%;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.FormPage {
  margin-top: 5%;
  width: 50%;
  height: 100vh;
  background-color: #141414;
  margin-left: 25%;
  margin-right: 25%;
}

@media (max-width: 900px) {
  body {
    text-align: center;
  }

  .wrapper {
    text-align: start;
  }

  .firstContent {
    background-image: url('./Media/BackgroundFirstContentMobile.webp');
    height: 700px;
  }

  .teamPerson {
    padding-left: 5%;
    padding-right: 5%;
    max-width: 100%
  }

  .wrapper {
    font-size: 50px;
  }

  .dynamic-txts h3 {
    font-size: 50px;
  }

  .teamSubDiv {
    flex-direction: column;
  }

  .header {
    position: relative;
  }

  .headerSide button {
    display: none;
  }

  .middlePageHelpButton {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .servicesDiv {
    margin-left: 0;
    margin-right: 0;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .clientsImgDiv img{
    max-width: 45%;
    max-height: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  .clientsDiv {
    margin-bottom: 5%;
  }

  .contactDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .contactInfo {
    width: 100%;
  }
  
  .contactForm {
    width: 100%;
  }

  .contactInfo h2 {
    text-align: center;
  }

  button:hover {
    background-color: #21ce5f;
    color: black;
  }

  .propositionDiv {
    max-width: 100%;
  }

  .adventuresDiv img{
    max-width: 100%;
  }

  .webdesignPrinciples h1 {
    font-size: 48px;
  }

  .webdesignPrinciples {
    align-items: center;
  }

  .webdesignPrinciple {
    max-width: 100%;
  }

  .webdesignPrinciples h3 {
    font-size: 35px;
  }

  .webdesignProjectsDiv h1{
    font-size: 48px;
  }

  .webdesignProject h3 {
    font-size: 35px;
  }

  .webdesignPrinciple {
    flex-direction: column;
  }

  .webdesignPrinciple img {
    max-width: 70%;
  }

  .webdesignPrincipleText {
    padding: 15px;
    margin: 0;
    margin-bottom: 25px;
  }

  .propositionKaizenLogo {
    max-width: 80%;
  }
  
  .webdesignPrincipleText h3 {
    font-weight: bold;
  }

  .FormPage {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }

  .certificationsDiv {
    flex-direction: column;
    max-height: none;
  }
}