/* Main:
*/
html {
    scroll-behavior: smooth;
}

.mmaMainDiv {
    margin-top: 90px;
}

@media (max-width: 900px) {
    .mmaMainDiv {
        margin-top: 31px;
    }
}

/* Blue button Style:
*/
.blueButton {
    width: 242px;
    height: 60px;

    background: #2E2A83;
    color: white;

    margin: 0;
    width: 242px;
    height: 60px;
    
    font-size: 22px;
    line-height: 32px;
}

.blueButton:hover {
    background: #21CE5F;
    color: black;
}

/* Header:
*/
.mmaHeader {
    display: flex;
    margin-left: 12.5%;
    margin-right: 12.5%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
}

@media (max-width: 900px) {
    .mmaHeader {
        flex-direction: column;
        margin-right: 24.5%;
        margin-left: 24.5%;
    }
}

.mmaHeader img {
    width: 204px;
    height: 65.9px;
}

.mmaHeader button {
    width: 242px;
    height: 60px;

    line-height: 22px;
    font-weight: 500;
    font-size: 18px;
    padding: 0;
}

@media (max-width: 900px) {
    .mmaHeader button {
        margin-top: 24px;
        width: 162px;
        height: 40px;
        font-size: 12px;
        line-height: 14px;
    }
}

/* First Content:
*/
.mmaFirstContent {
    display: flex;
    flex-direction: row;
    padding-left: 12.5%;
    padding-right: 12.5%;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url("../../../Media/BackgroundFirstContent.webp");
    background-size: auto;
    background-position-y: -200px;
}

@media (max-width: 900px) {
    .mmaFirstContent {
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: column-reverse;
        background-position-y: 0px;
    }
}

.mmaFirstContent h1 {
    margin: 0;
    width: 703px;
    height: 308px;
    font-weight: 600;
    
    font-size: 64px;
    line-height: 77px;
    
    color: #FFFFFF;

    padding-bottom: 24px;
}

@media (max-width: 900px) {
    .mmaFirstContent h1 {
        width: 359px;
        height: 268px;
        font-size: 44px;
        line-height: 53px;
        text-align: left;
    }
}

.mmaFirstContent p {
    margin: 0;
    width: 623px;
    height: 180px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    padding-bottom: 27px;
}

@media (max-width: 900px) {
    .mmaFirstContent p {
        width: 352px;
        height: 186px;
        font-size: 18px;
        line-height: 150%;
        text-align: left;
    }
}

@media (max-width: 900px) {
    .mmaFirstContent .textWrapper {
        top: 250px;
    }
    .mmaFirstContent .imageWrapper {
        left: 0px;
        top: 46px;
    }
    .mmaFirstContent .imageWrapper img {
        width: 148px;
        height: 148px;
    }
}

.mmaRestOfContent {
    display: flex;
    flex-direction: column;
    margin-left: 12.5%;
    margin-right: 12.5%;
}

@media (max-width: 900px) {
    .mmaRestOfContent {
        margin-left: 5%;
        margin-right: 5%;
    }
}

/* Second Content:
*/

.mmaSecondContent {
    margin-top: 6.35%;
    width: 100%
}

.mmaSecondContent h2 {
    text-align: center;
    font-size: 32px;
}

.mmaSecondContent .mmaSecondContentContainer {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    display: grid;
    column-gap: 20px;
    row-gap: 30px;
    grid-template-columns: auto auto;

    padding: 65px 9.5% 65px 9.5%;
}

.mmaSecondContent .mmaSecondContentContainer h3 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
    color: #FFFFFF;
}

.mmaSecondContent .mmaSecondContentContainer p {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

@media (max-width: 900px) {
    .mmaSecondContent {
        margin-left: auto;
        margin-right: auto;
        margin-top: 61px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mmaSecondContent .mmaSecondContentContainer {
        grid-template-columns: auto;
        row-gap: 38px;
        padding: 29px 20px 29px 20px;
    }

    .mmaSecondContent .mmaSecondContentContainer img {
        width: 58px;
        height: 58px;
    }

    .mmaSecondContent .titleWrapper h3 {
        margin-top: 0px;
        display: flex;
        align-items: center;
        text-align: start;
    }

    .mmaSecondContent .mmaSecondContentContainer p {
        text-align: left;
    }
}

.mmaSecondContentContainer .groupWrapper {
    margin-right: 12px;
}

.mmaSecondContent .titleWrapper {
    display: inline-flex;
    width: 100%;
}

.mmaSecondContent .titleWrapper img {
    padding-right: 13px;
}

.mmaSecondContent .itemWrapper p {
    margin: 0px 5% 0 18%;
}

/* Third Content:
*/
.mmaThirdContent {
    margin-top: 94px;
}

.mmaThirdContent h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    margin-bottom: 63px;
}

.mmaThirdContent .mmaThirdContentContainer {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 900px) {
    .mmaThirdContent .mmaThirdContentContainer {
        flex-direction: column;
        gap: 38px;
    }
}

.mmaThirdContent .mmaThirdContentContainer h2 {
    color: white;
    margin: 0;
    font-weight: 600;
}

.mmaThirdContent .mmaThirdContentContainer p {
    margin: 0;
    margin-top: 5px;
    padding: 0 5% 0 5%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

/* Forth Content:
*/
.mmaFourthContent {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mmaFourthContent h2 {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 63px;
}

.mmaWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
}

.mmaWrapper > ul {
    font-weight: 400;
    padding-left: 25px;
    font-size: 18px;
}

.mmaFourthContent .buttonWrapper {
    margin-top: 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mmaFourthContent .buttonWrapper p {
    font-family: 'Lembra';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    text-align: center;
    align-items: center;
}

.mmaWrapper > p {
    font-size: 24px;
}

.mmaFourthContent > ul {
    margin-left: 15%;
}

@media (max-width: 900px) {
    .mmaFourthContent {
        display: none;
    }
}

/* Fifth Content:
*/
.mmaFifthContent {
    margin-top: 94px;

}

.mmaFifthContentContainer {
    margin-left: 12%;
    margin-right: 12%;
}

.mmaFifthContentContainer img {
    max-width: 32px;
    height: auto;
    margin-right: 18px;
}

.mmaFifthContent > h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
}

.mmaFifthContentContainer > p {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
}

.mmaFifthContent .mmaFifthContentContainer {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 38px 95px 38px 95px;
    margin-top: 55px;
}

@media (max-width: 900px) {
    .mmaFifthContent > .mmaFifthContentContainer {
        padding: 27px 15px 27px 15px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .mmaFifthContentContainer > div p {
        font-size: 18px;
        text-align: start;
    } 

    .mmaFifthContentContainer > img {
        max-width: 20px;
        height: auto;
    }
}

.mmaFifthContentContainer div {
    display: inline-flex;
}

.mmaFifthContent .buttonWrapper {
    margin-top: 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mmaFifthContent .buttonWrapper p {
    margin-top: 7px;

    font-family: 'Lembra';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    justify-content: center;
    display: flex;
    text-align: center;
    align-items: center;
}

/* Sixth Content:
*/
.mmaSixthContent {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mmaSixthContent h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
}

.mmaSixthContentImagesContainer {
    margin-top: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}

.mmaSixthContentImagesContainer > img {
    width: 15%;
    height: auto;
}

@media (max-width: 900px) {

    .mmaSixthContentImagesContainer {
        width: 95%;
    }

    .mmaSixthContentImagesContainer > img {
        width: 60%;
    }

    .mmaSixthContent .mmaSixthContentImagesContainer {
        gap: 40px;
        flex-direction: column;
        align-items: center;
    }
}

/* Seventh Content:
*/
.mmaSeventhContent {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mmaSeventhContent h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
}

.mmaSeventhContent .mmaSeventhContentImagesContainer {
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
    margin-left: 22.22%;
    margin-right: 22.22%;
}

.mmaSeventhContentImagesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}

.mmaSeventhContentImagesContainer > img {
    width: 20%;
    height: auto;
}

@media (max-width: 900px) {
    .mmaSeventhContentImagesContainer {
        width: 95%;
    }
    
    .mmaSeventhContentImagesContainer > img {
        width: 60%;
        height: auto;
    }

    .mmaSeventhContent .mmaSeventhContentImagesContainer {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
}

/* Eight Content:
*/
.mmaEighthContent {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
}

.mmaEighthContent h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
}

.mmaEighthContent > .mmaEighthContentContainer {
    margin-top: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 17%;
    margin-right: 17%;
}

.mmaEighthContentContainer > img {
    margin-right: 104px;
}

.mmaEighthContentContainer > p {
    font-size: 18px;
}

@media (max-width: 900px) {
    .mmaEighthContentContainer > img {
        margin-right: 0;
    }

    .mmaEighthContentContainer {
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }
}

/* Form:
*/
.mmaFormContainer {
    margin-top: 94px;
}

.mmaFormContainer h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
}

.mmaFormContainer p {
    margin-top: 63px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.mmaFormContainer .pipedriveWebForms {
    margin-top: 55px;
    display: flex;
    justify-content: center;
    margin-left: 22.22%;
    margin-right: 22.22%;
    margin-bottom: 92px;
    width: auto;
}