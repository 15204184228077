.openHouseLandingPage {
  background-color: #141414;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 14%;
  margin-right: 14%;
  font-weight: 100;
}

.openHouseHeader {
  margin-top: 118px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textAndIcon {
  color: #FFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.textAndIcon > p {
  font-size: 24px;
  margin: 0;
  text-decoration-line: underline;
  cursor: pointer;
  margin-right: 4px;
}

.textAndIcon > img {
  width: 24px;
  height: auto;
}

.openHouseKato {
  width: 100%;
  box-sizing: border-box;
  background: rgba(217, 217, 217, 0.13);
  border-radius: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 2% 2% 2% 2%;
}

.katoContainer {
  margin-left: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 234px;
  height: 100%;
}

.katoContainer > img {
  width: 100%;
  height: auto;
}

.katoTextContainer {
  font-style: normal;
  text-align: justify;
  width: 564px;
}

.katoTextContainer > h2 {
  color: #FFF;
  font-weight: 100;
  font-family: 'Open Sans';
  font-size: 96px;
  line-height: 100px;
  margin: 0;
}

.katoTextContainer > p {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 34px;
  font-weight: 100;
}

.openHouseInvitation {
  width: 70%;
  margin-top: 60px;
  margin-bottom: 225px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.openHouseInvitation > p a {
  color: #FFF;
  margin: 0;
  font-size: 24px;
  line-height: 34px;
}

.openHouseInvitation > h2 {
  color: #FFF;
  font-weight: 100;
  margin: 0;
  font-size: 96px;
  line-height: 100px;
}

.openHouseInvitation > button {
  display: flex;
  flex-direction: row;
  color: #21CE5F;
  background-color: #141414;
  border: 1px solid #21CE5F;
  width: 211px;
  align-items: center;
  height: 60px;
}

.openHouseInvitation > button:hover {
  transition: 0.25s;
  color: #FFF;
  border: 1px solid #FFF;
}

.openHouseInvitation > button p {
  font-size: 20px;
  margin: 0;
}

.openHouseInvitation > button div {
  width: 33%;
}

#openhead {
  width: 252px;
  height: 60px;
}

#openhead > img {
  width: 100%;
  height: auto;
}

#firstHouseOpen {
  margin-top: 4%;
  margin-bottom: 6%;
}

@media (min-width: 1024px) {
  
  #katoThanks {
    width: 362px;
    height: 403px;
  }

  #katoThanksText {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 495px;
    height: 394px;
  }
}



@media (max-width: 1023px) {
  .openHouseLandingPage {
    margin-left: 57px;
    margin-right: 57px;
  }

  .openHouseHeader {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
  }

  .textAndIcon > p {
    font-size: 10px;
  }

  .textAndIcon > img {
    width: 10px;
  }

  .openHouseKato {
    flex-direction: column;
    margin-top: 40px;
    padding: 45px 34px;
  }

  .katoContainer {
    align-self: center;
    width: 30%;
    margin: 0;
  }

  .katoTextContainer {
    width: 100%;
  }

  .katoTextContainer > h2 {
    font-weight: 100;
    text-align: start;
    font-size: 76px;
    line-height: 80px;
  }

  .katoTextContainer > p {
    text-align: start;
    font-size: 18px;
    line-height: 24px;
    margin-left: 10px;
  }

  #openhead {
    max-width: 130px;
  }

  #katoThanksText {
    height: auto;
  }

  #katoThanksText > h2 {
    font-size: 56px;
    line-height: 60px;
  }

  #firstHouseOpen {
    margin-top: 10%;
    margin-bottom: 14%;
  }
}