.PartnersLandingPage {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #141414;
}

.PartnersLandingPage h2 {
  line-height: 76px;
  font-size: 64px;
}

.PartnersLandingPage h3 {
  font-weight: 500;
  color: #FFF;
  font-size: 52px;
  line-height: 62px;
}

.PartnersLandingPage p {
  font-size: 28px;
  line-height: 42px;
}

.kaizenAdventuresImagesContainer {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kaizenAdventuresImagesContainer img{
  width: 45%;
  height: auto;
}

.blueText {
  background-color: #2e2a83;
}

#marquinhos {
  font-size: 28px;
  line-height: 42px;
  max-width: 50%;
}

#marquinhosImage {
  max-width: 161px;
  height: auto;
}

.bulletPoints {
  text-align: start;
  margin-top: 5%;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.bulletPoints li {
  font-size: 32px;
  color: #21ce5f;
}

.bulletPoints p {
  font-size: 24px;
  line-height: 36px;
}

.imageAndText {
  margin-bottom: 14px;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageAndText a {
  margin-left: 12px;
  font-size: 24px;
  line-height: 36px;
  color: #FFF;
}

.hardText {
  font-weight: 600;
}

#biggerImageAndText {
  margin-left: 25%;
  align-self: flex-start;
}

#biggerImageAndText a {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

#listening {
  width: auto;
  max-height: 159px;
}

#textToStart {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.textToStart {
  text-align: start;
}

.greenText {
  color: #21ce5f;
}

.formDiv {
  border-radius: 20px;
  width: 70%;
  margin-top: 70px;
  margin-bottom: 70px;
  margin-left: 15%;
  margin-right: 15%;
  background-color: #222;
}

.formDiv h3 {
  text-align: start;
  margin-left: 130px;
  max-width: 50%;
  color:#21ce5f ;
  font-size: 52px;
  line-height: 62px;
}

.formDiv p {
  font-size: 28px;
  line-height: 42px;
}

.blackText {
  background-color: black;
}

#formAndText {
  display: flex;
  flex-direction: row;
}

#formAndText p {
  text-align: start;
  margin-left: 10%;
  margin-right: 10%;
  width: 30%;
}

#formAndText div {
  width: 50%;
}

@media (max-width: 1023px) {
  .PartnersLandingPage h2 {
    line-height: 44px;
    font-size: 36px;
  }

  .PartnersLandingPage h3 {
    font-size: 30px;
    line-height: 36px;
  }

  .kaizenAdventuresImagesContainer {
    width: 90%;
  }

  #marquinhos {
    font-size: 16px;
    line-height: 24px;
    max-width: 80%;
  }

  .PartnersLandingPage p {
    font-size: 16px;
    line-height: 24px;
  }

  .bulletPoints li {
    text-align: center;
    list-style: none;
    font-size: 20px;
    color: #21ce5f;
  }

  .bulletPoints p {
    font-size: 14px;
    line-height: 21px;
  }

  .imageAndText a {
    font-size: 14px;
    line-height: 21px;
  }

  #biggerImageAndText a {
    font-size: 20px;
    line-height: 24px;
  }

  .formDiv h3 {
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    max-width: 90%;
    font-size: 30px;
    line-height: 36px;
  }

  .formDiv p {
    font-size: 16px;
    line-height: 24px;
  }

  #formAndText {
    display: flex;
    flex-direction: column;
  }

  #formAndText p {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }
  
  #formAndText div {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }

  #textToStart {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .bulletPoints {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .formDiv {
    width: 80%;
    margin-left: 20%;
    margin-right: 20%;
  }
  
}

